.about {
  width: 100vw;
  max-width: 100%;
  height: max-content;
  background-image: url("../../../assets/HeroBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.about > div {
  padding: 7vmax 0;
}

.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  max-width: 100%;
  object-fit: cover;
  z-index: 1;
  background-color: #0000008c;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7vmax;
}

.aboutContainer > div:first-child > h3 {
  text-align: start;
  font-size: 3vmax;
  color: #e6c068;
  font-family: serif;
  position: relative;
  z-index: 2;
}

.aboutContainer > div:last-child {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.aboutContainer > div:last-child > div {
  display: flex;
  gap: 2vmax;
}

.aboutContainer > div:last-child > div > div:first-child {
  width: 22vmax;
  height: 22vmax;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutContainer > div:last-child > div > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 2vmax;
  width: 30%;
}

.aboutContainer > div:last-child > div > div:last-child > h4 {
  font-size: 1.8vmax;
  color: #dfac35;
  font-family: serif;
  position: relative;
  z-index: 2;
}

.aboutContainer > div:last-child > div > div:last-child > h4 > p {
  margin-top: 0.5vmax;
  font-size: 1.2vmax;
  color: #e6c068;
  font-family: serif;
  letter-spacing: 0.2vmax;
}

.a-card1 > div:last-child > h4 > p {
  text-align: start;
}

.a-card2 > div:last-child > h4 > p {
  text-align: end;
}

.a-card1 > div:last-child > h4 {
  text-align: start;
}

.a-card1 > div:last-child > div > p {
  text-align: start;
}

.a-card2 > div:last-child > h4 {
  text-align: end;
}

.a-card2 > div:last-child > div > p {
  text-align: end;
}

.aboutContainer > div:last-child > div > div:last-child > div > p {
  font-size: 1.2vmax;
  color: #e6c068;
  position: relative;
  z-index: 2;
}

.aboutImage {
  width: 20vmax;
  border-radius: 100%;
  position: relative;
  /* transform: translateY(-2.5vmax); */
  z-index: 2;
  visibility: hidden;
  transition: all 1s;
}

.a-card1 > div:first-child {
  transition: all 1s ease-in-out;
  background: url("../../../assets/ASIM/ASIM1.png");
  background-size: contain;
  /* border-radius: 100%; */
  transition: all 0.5s;
}

.a-card2 > div:first-child {
  transition: all 1s ease-in-out;
  background: url("../../../assets/YASH/YASH1.png");
  background-size: contain;
  border-radius: 100%;
  transition: all 0.5s;
}

.a-card1 > div:first-child:hover {
  background: url("../../../assets/ASIM/ASIM2.png");
  background-size: contain;
  transform: scale(1.1);
  z-index: 3;
}

.a-card2 > div:first-child:hover {
  background: url("../../../assets/YASH/YASH2.jpg");
  background-size: contain;
  transform: scale(1.1);
  z-index: 3;
}

.a-card2 {
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.social {
  padding-top: 2vmax;
  transition: all ease-in-out;
}

.s2 {
  align-self: flex-end
}

.social > a {
  text-decoration: none;
  padding: 1vmax 2vmax;
  font-size: 0.8vmax;
  color: #e6c068;
  text-transform: uppercase;
  font-weight: 600;
  font-family: serif;
  letter-spacing: 0.2vmax;
}

.social > a:last-child {
  border-left: none;
  background-color: #e6c068;
  color: #1a1917;
}

@media screen and (max-width: 1050px) {
  .aboutContainer > div:last-child {
    gap: 5vmax;
  }
}

@media screen and (max-width: 750px) {
  .aboutContainer > div:last-child {
    gap: 6vmax;
  }

  .aboutContainer > div:last-child > div {
    flex-direction: column;
  }

  .aboutContainer > div:last-child > div > div:first-child {
    width: 20vmax;
    height: 20vmax;
    align-self: center;
  }

  .aboutContainer > div:last-child > div > div:last-child {
    width: 60%;
    align-self: center;
  }

  .aboutImage {
    transform: none;
  }

  .social > a {
    font-size: 1.2vmax;
  }
}
