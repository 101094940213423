.perk {
  width: 100vw;
  max-width: 100%;
  height: max-content;
  background-color: #1a1917;
  overflow: hidden;
  padding: 7vmax 0;
}

.perkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vmax;
}

.perkContainer > div:first-child {
  position: relative;
  padding-bottom: 7vmax;
}

.perkContainer > div:first-child > span {
  position: absolute;
}

.perkContainer > div:first-child > span > svg {
  font-size: 3vmax;
  color: #e6c068;
}

.pcsp-2 > svg {
  font-size: 2vmax !important;
}

.pcsp-2 {
  top: -10%;
  right: 0;
}

.pcsp-3 {
  bottom: 0;
  left: 40%;
}

.pcsp-3 > svg {
  font-size: 5vmax !important;
}

.perkContainer > div:first-child > div {
  display: flex;
  flex-direction: column;
}

.perkContainer > div:first-child > div > h3 {
  font-size: 3.5vmax;
  color: white;
}

.perkContainer > div:first-child > div > h3:first-child {
  align-self: flex-end;
  transform: translateX(2vmax);
  display: flex;
  gap: 1vmax;
}

.perkContainer > div:first-child > div > h3:first-child > span {
  font-size: 5.5vmax;
  color: #e6c068;
}

.perkContainer > div:first-child > div > h3:last-child {
  transform: translateX(-5vmax);
}

.perkContainer > div:last-child {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 4vmax;
}

.perkContainer > div:last-child > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vmax;
}

.perkContainer > div:last-child > div > img {
  width: 10vmax;
}

.perkContainer > div:last-child > div > p {
  font-size: 1.5vmax;
  color: white;
  width: 70%;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .perkContainer > div:last-child {
    grid-template-columns: repeat(2, 1fr);
  }

  .perkContainer > div:first-child > div > h3:last-child {
    transform: translateX(-3vmax);
  }
}
