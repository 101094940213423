.loaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1917;
  z-index: 100;
  position: sticky;
  inset: 0;
}

.loader {
  position: relative;
  width: 5rem;
  height: 5rem;
}

.loader > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loader > span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2em;
  height: 0.2em;
  border-radius: 50%;
  background-color: #e6c068;
  box-shadow: 0 0 0.01em #e6c068, 0 0 0.02em #e6c068, 0 0 0.04em #e6c068,
    0 0 0.06em #e6c068, 0 0 0.08em #e6c068, 0 0 0.1em #e6c068;
  animation: loadingAnimation 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes loadingAnimation {
    0% {
        transform: scale(0.1);
    }
    30%, 100% {
        transform: scale(3);
    }
}