.testCard {
  width: 38vw;
  height: 65vh;
  background-color: #2b2926;
  overflow: hidden;
  padding: 1.2vmax;
  border-radius: 1.2vmax;
}

.testCard > div {
  height: 100%;
}

.testCardContainer {
  height: 90%;
  display: flex;
  gap: 1vmax;
}

.testCardContainer > div {
  background-color: white;
  height: 100%;
  padding: 0.8vmax;
  border-radius: 1.2vmax;
}

.testCardContainer > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 2vmax;
}

.testCardContainer > div:first-child > div {
  height: 80%;
  width: 15vw;
  /* background-color: #e6c068; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.testCardContainer > div:first-child > div > img {
  width: 70%;
}

.testCardContainer > div:first-child > span > p {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5vmax;
  font-weight: 600;
  color: #2b2926;
}

.testCardContainer > div:first-child > span > p:last-child {
  font-size: 1.2vmax;
}

.testCardContainer > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vmax;
  background-color: #d5b25e;
  position: relative;
}

.testCardContainer > div:last-child > span {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-1vmax, 1.6vmax);
}

.testCardContainer > div:last-child > span > img {
  width: 5vmax;
}

.testCardContainer > div:last-child > p {
  font-size: 1.5vmax;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 97%;
  color: #454545;
  align-self: center;
}

.testCardContainer > div:last-child > div {
  display: flex;
  gap: 1vmax;
  justify-content: center;
  align-items: center;
}

.testClientImage {
  width: 4vmax;
  border: 2px solid white;
  border-radius: 100%;
}

.testCardContainer > div:last-child > div > a {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5vmax;
  font-weight: 600;
  color: #2b2926;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 1050px) {
  .testCard {
    height: 30vh;
  }

  .testCardContainer > div:last-child > div > a {
    font-size: 1.2vmax;
  }
}

@media screen and (max-width: 750px) {
  .testCard {
    height: 30vh;
  }

  .testCardContainer > div:first-child {
    display: none;
  }
}
