.Hero {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-image: url("https://res.cloudinary.com/de0lgy8rs/image/upload/v1705251466/Kaizen%20Studio/h0beji33h0qccksdqgyl.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 8vmax;
  overflow: hidden;
  position: relative;
}

.heroChalla-1 {
  position: absolute;
}
.heroChalla-2 {
  position: absolute;
  bottom: -15%;
  right: -15%;
}

.heroChalla-1 > img {
  width: 20vmax;
  transform: rotate(180deg);
}
.heroChalla-2 > img {
  width: 30vmax;
}

.HeroContainer {
  display: flex;
  justify-content: space-around;
  height: 80vh;
}

.h-1,
.h-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.h-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-2 > div {
  display: flex;
  flex-direction: column;
  gap: 4vmax;
  position: relative;
}

.h-2 > div > div > h3 {
  text-align: center;
  font-size: 4vmax;
  color: #e6c068;
  font-family: serif;
  z-index: 2;
  position: relative;
  transition: all 2s ease-in-out;
  animation: popup 1s forwards 0s ease-in-out;
}

.h-2 > div > div > h3:last-child {
  animation-duration: 2s;
}

.heroimgspan {
  position: absolute;
  z-index: 2;
  bottom: -10%;
  right: 5%;
}

.heroimgspan > img {
  width: 7vmax;
  transform: rotate(10deg);
}

.h-2 > div > p {
  text-align: center;
  color: #e6c068;
  font-size: 2.2vmax;
  letter-spacing: 1vmax;
  z-index: 2;
  transition: all 2s ease-in-out;
  animation: popup 3.5s forwards 0s ease-in-out;
}

.h-1 > div > img {
  width: 5vmax;
  transition: all 5s ease-in-out;
  animation: animate1 5s infinite 0s;
  z-index: 2;
  position: relative;
}

.h-3 > div > img {
  width: 5vmax;
  transition: all 5s ease-in-out;
  animation: animate2 5s infinite 0.9s;
  z-index: 2;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  padding-top: 8vmax;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
  background-color: #0000008c;
}

.instagram {
  right: 10vmax;
  bottom: 3vmax;
  animation: animate2 5s infinite 0.3s !important;
}

.tiktok {
  left: 10vmax;
  animation: animate1 5s infinite 0.6s !important;
}

@keyframes popup {
  0% {
    transform: translateY(25%) scale(0.7, 0.7);
    visibility: hidden;
    opacity: 0;
    z-index: 5;
  }
  100% {
    transform: translateY(0%) scale(1, 1);
    visibility: visible;
    opacity: 1;
    z-index: 5;
  }
}

@keyframes animate1 {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes animate2 {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@media screen and (max-width: 750px) {
  .h-2 > div > div > h3 {
    font-size: 3vmax;
  }

  .heroimgspan {
    display: none;
  }
}
